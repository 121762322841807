import './polyfills';
import { registerApplication, start } from 'single-spa';
import * as serviceWorker from './serviceWorker';
import { setLoadApplication } from './utils';

registerApplication(
  'legacy',
  setLoadApplication({
    app: '@creditas/legacy',
  }),
  () => true,
);
start();

serviceWorker.unregister();
