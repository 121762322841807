/* eslint-disable @typescript-eslint/no-explicit-any */

interface LoadApplication {
  app: string;
  testAB?: RegExp;
  prefixVersion?: string;
}

declare global {
  interface Window {
    System: any;
  }
}

const hasTestABonURL = (regExp: RegExp, url: string) => {
  return !!regExp.test(url);
};

const setNameApp = (params: LoadApplication) => {
  if (params.testAB && params.prefixVersion) {
    return hasTestABonURL(params.testAB, window.location.href)
      ? `${params.app}${params.prefixVersion}` : params.app;
  }
  return params.app;
};

const setLoadApplication = ( params: LoadApplication ) => {
  const nameApp = setNameApp(params);
  return (): Promise<any> => window.System.import(nameApp);
};

export {
  setLoadApplication,
  setNameApp
};
